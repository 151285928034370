import { createGlobalStyle } from 'styled-components';
import Color from 'color';

export const shades = {
  0: '#FFFFFF',
  50: '#F8F9F9',
  100: '#EDEFF0',
  200: '#E2E4E6',
  300: '#D6DADC',
  350: '#BDC2C5',
  400: '#838C91',
  500: '#676D70',
  600: '#333333',
  700: '#222222',
  800: '#111111',
  900: '#000000',
};

export const GlobalStyle = createGlobalStyle`
  html, body {
    padding: 0;
    margin: 0;
    height: 100%;
    width: 100%;
    font-family: 'Fjord One', serif;
    font-style: normal;
    font-weight: 400;
    font-size: ${props => props.theme['font-size-normal']};
    background: ${props => props.theme['black']};
    color: ${props => props.theme['white']};
  }

  @font-face {
    font-family: "FontAwesome";
    font-weight: normal;
    font-style: normal;
  }

  #root {
    height: 100%;
    min-height: 100%;
    width: 100%;
    display: flex;

    > .spinner-border {
      position: absolute;
      margin: auto;
      top: calc(50% - (2rem / 2));
      left: calc(50% - (2rem / 2));
      color: ${props => props.theme['primary']};
    }
  }

  .modal-open {
    padding-right: 0px !important;
  }

  h1 {
    font-size: ${props => props.theme['font-size-xlarge']};
    font-weight: 600;
  }

  h2 {
    font-size: ${props => props.theme['font-size-large']};
    font-weight: 600;
  }

  h3 {
    font-size: ${props => props.theme['font-size-medium']};
    font-weight: 500;
  }

  h4 {
    font-weight: 600;
    font-size: ${props => props.theme['font-size-medium']};
    color: ${props => props.theme['black']};
  }

  small {
    font-weight: 500;
    font-size: ${props => props.theme['font-size-small']};
    color: ${props => props.theme.shades['500']};
  }

  a {
    text-decoration: none !important;
    color: ${props => props.theme['hyperlink']};
  }

  button, input, optgroup, select, textarea {
    font-weight: 600;
  }

  .form-control {
    font-weight: 500;
    border: 1px solid ${props => props.theme.shades['350']};
  }

  input.form-control, select.form-control {
    height: ${props => props.theme['input-height']};
    color: ${props => props.theme['black']};
    appearance: auto;
  }

  textarea.form-control {
    color: ${props => props.theme['black']};
  }

  input.form-control:focus, select.form-control:focus, textarea.form-control:focus {
    color: ${props => props.theme['black']};
  }

  input.form-control::placeholder, textarea.form-control::placeholder {
    color: ${props => props.theme.shades['300']};
  }

  .form-check-input:checked[type=checkbox] {
    background-image: none;
  }

  .form-check-input[type=radio] {
    width: 20px;
    height: 20px;
    margin: 0px;

    &:checked {
      border-color: ${props => props.theme['primary']};
      background-color: ${props => props.theme['primary']};
    }
  }

  .jumbotron {
    padding: 44px;
  }

  .form-group {
    margin-bottom: 1rem;
  }

  .sr-only {
    display: none;
  }

  .container {
    padding-top: ${props => props.theme['padding-medium']};
    padding-bottom: ${props => props.theme['padding-medium']};

    @media (min-width: 1400px) {
      max-width: 1140px;
    }

    > button {
      margin: ${props => props.theme['margin-medium']} 0px ${props =>
  props.theme['margin-medium']} ${props => props.theme['margin-medium']};
      float: right;
    }

    &:after {
      clear: both;
      height: 0;
      content: '';
      display: block;
    }
  }

  label, label.form-control {
    font-size: ${props => props.theme['font-size-normal']};
    color: ${props => props.theme['black']};
    margin-bottom: .5rem;
  }

  label.tiny {
    font-size: ${props => props.theme['font-size-tiny']};
  }

  button, .btn {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0px ${props => props.theme['padding-normal']};
    font-weight: 400;
    font-size: ${props => props.theme['font-size-medium']};
    height: ${props => props.theme['button-height']};
    border-radius: ${props => props.theme['radius-normal']};
    box-shadow: rgba(0, 0, 0, 0.3) 0px 1px 3px 0px;

    svg {
      margin: 0px ${props => props.theme['margin-small']} 0px 0px;
    }

    &.icon {
      svg {
        margin: 0px;
      }

      /* height: auto; */
      background: transparent;
      border: none;
      color: ${props => props.theme['black']};
      font-size: ${props => props.theme['font-size-regular']};
      width: auto;
      box-shadow: none;

      &:hover {
        background: rgb(0, 0, 0, 0.1);
      }

      &.light {
        color: ${props => props.theme['white']};
      }
    }

    &.overlay {
      border: none;
      box-shadow: rgba(0, 0, 0, 0.2) 0px 2px 4px 0px;
    }

    &.popup {
      box-shadow: none;
      border: none;
      border-radius: 0px;
      margin: 0px;
      text-align: left;
      color: ${props => props.theme['primary']};

      &:hover {
        background-color: ${props => props.theme['primary']};
        color: ${props => props.theme['white']};
      }

      svg {
        width: 18px;
      }
    }

    &.create {
      text-align: center;
      border: none;
      color: ${props => props.theme['primary']};
      background: ${props => props.theme['white']};

      svg {
        margin: 0px;
      }

      &:hover {
        background: transparent;
        color: ${props => Color(props.theme['primary']).darken(0.15).string()};
        border-color: rgb(0, 0, 0, 0.2);
      }
    }

    &.link {
      box-shadow: none;
      border: none;
      color: ${props => props.theme['secondary']};
      background: transparent;
      text-decoration: underline;
    }

    .spinner-border {
      width: 16px;
      height: 16px;
      border-width: 3px;
      margin-right: ${props => props.theme['margin-small']};
    }
  }

  .nav-container {
    .nav-link {
      position: relative;
    }
  }
`;

export const defaultTheme = {
  shades,
  'background-primary': '#EBEBEB',
  primary: '#473E65',
  'primary-dark': '#0E132D',
  secondary: '#ACEDFF',
  hyperlink: '#3f43ba',
  white: shades['200'],
  black: shades['900'],
  grey: '#D2D2D2',
  'grey-light': '#F8F8F8',
  'grey-dark': '#999999',
  cream: '#D0CBC5',
  'light-brown': '#D4CEC8',
  'dark-brown': '#7C675F',
  'secondary-light': '#d1f5ff',
  'secondary-dark': '#94E0F7',
  'primary-font-family': "'Fjord One', serif",
  'secondary-font-family': "'Inconsolata', monospace",
  'input-height': '40px',
  'button-height': '40px',
  'font-size-title': '48px',
  'font-size-content': '22px',
  'font-size-xxlarge': '48px',
  'font-size-xlarge': '32px',
  'font-size-large': '24px',
  'font-size-medium': '18px',
  'font-size-normal': '14px',
  'font-size-small': '12px',
  'font-size-tiny': '10px',
  'radius-button': '10px',
  'radius-xlarge': '12px',
  'radius-large': '6px',
  'radius-normal': '4px',
  'radius-normal-hollow': '5px',
  'radius-small': '2px',
  'margin-tiny': '3px',
  'margin-small': '6px',
  'margin-normal': '12px',
  'margin-medium': '18px',
  'margin-large': '24px',
  'margin-xlarge': '32px',
  'padding-tiny': '3px',
  'padding-small': '6px',
  'padding-normal': '12px',
  'padding-medium': '18px',
  'padding-large': '24px',
  'padding-xlarge': '32px',
  'padding-xxlarge': '64px',
  'margin-card': '14px',
  'margin-list': '30px',
  'margin-list-header': '48px',
  'margin-list-item': '14px',
  'caret-width-normal': '30px',
};
