import React, { Component, Suspense } from 'react';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import { ThemeProvider } from 'styled-components';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import { Spinner } from 'reactstrap';
import { library } from '@fortawesome/fontawesome-svg-core';
import { GlobalStyle, defaultTheme } from './theme/globalStyle';
import { AbilityContext } from './config/Can';
import ability from './config/ability';
import {
  faEnvelope,
  faChevronLeft,
  faChevronRight,
  faLink,
} from '@fortawesome/free-solid-svg-icons';
import {
  faFacebook,
  faInstagram,
  faYoutube,
  faDiscord,
  faXTwitter,
} from '@fortawesome/free-brands-svg-icons';

const Home = React.lazy(() => import('./components/Home/Home'));

const _setupFontAwesomeLibrary = () => {
  library.add(faEnvelope);
  library.add(faFacebook);
  library.add(faInstagram);
  library.add(faXTwitter);
  library.add(faYoutube);
  library.add(faDiscord);
  library.add(faChevronLeft);
  library.add(faChevronRight);
  library.add(faLink);
};

const App = () => {
  _setupFontAwesomeLibrary();

  return (
    <ThemeProvider theme={defaultTheme}>
      <AbilityContext.Provider value={ability}>
        <DndProvider backend={HTML5Backend}>
          <GlobalStyle />
          <BrowserRouter>
            <Suspense fallback={<Spinner />}>
              <Routes>
                <Route path="*" element={<Home />} />
              </Routes>
            </Suspense>
          </BrowserRouter>
        </DndProvider>
      </AbilityContext.Provider>
    </ThemeProvider>
  );
};

export default App;
